import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const HotelPage = () => (
  <Layout>
    <Seo title="CSICon Hotel Information" />
    <div class="row page-row">
      <div class="col-12 col-lg-8">
        <div class="page-header">
          <h1>
            <strong>Hotel Information</strong>
          </h1>
          <hr />
        </div>

        <div class="entry-content conference-max mt-3">
          <p>
            CSICon 2023 takes place in the conference area of the Flamingo Hotel
            and Casino.
          </p>
          <p>
            Discounted rooms are available at the Flamingo starting at
            $109/night. Links are located below to complete your reservation. We
            recommend booking early as our conference rooms have sold out in the
            past. Cutoff to book with the Flamingo at these special rates is
            10/3/2023.
          </p>
          <a
            href="https://book.passkey.com/go/SFCFI3"
            class="btn green-button-outline"
          >
            Stay at the Flamingo
          </a>
          <p>
            The hotel does not have a shuttle from the airport. Taxis are
            available 24 hours a day, and, there are several difference shuttle
            companies to choose from:
          </p>
          <ul>
            <li>
              <a href="https://www.vegas4locals.com/resources/las-vegas-airport-shuttles/">
                https://www.vegas4locals.com/resources/las-vegas-airport-shuttles/
              </a>
            </li>
            <li>
              Supershuttle: Phone - 1-800-258-3826 / Web:{" "}
              <a href="https://www.supershuttle.com/locations/lasvegas-las/">
                https://www.supershuttle.com/locations/lasvegas-las/
              </a>
            </li>
            <li>
              Harry Reid (formerly McCarron) Airport:{" "}
              <a href="https://www.harryreidairport.com/Transportation">
                https://www.harryreidairport.com/Transportation
              </a>
            </li>
          </ul>

          {/*} <table class="table">
            <tbody>
              <tr>
                <th>
                  Tuesday
                  <br />
                  10/15/2019
                </th>
                <th>
                  Wednesday
                  <br />
                  10/16/2019
                </th>
                <th>
                  Thursday
                  <br />
                  10/17/2019
                </th>
                <th>
                  Friday
                  <br />
                  10/18/2019
                </th>
                <th>
                  Saturday
                  <br />
                  10/19/2019
                </th>
                <th>
                  Sunday
                  <br />
                  10/20/2019
                </th>
                <th>
                  Monday
                  <br />
                  10/21/2019
                </th>
              </tr>
              <tr>
                <td>$89</td>
                <td>$89</td>
                <td>$89</td>
                <td>$129</td>
                <td>$129</td>
                <td>$89</td>
                <td>$89</td>
              </tr>
            </tbody>
          </table>*/}
          <p>
            Optional workshops take place on Thursday. CSICon Opening Reception
            takes place Thursday evening. CSICon main program all day Friday and
            Saturday, and Sunday morning.
          </p>
          {/*<p>
            <a
              href="https://book.passkey.com/go/SFCFI2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button class="btn green-button">Book Online</button>
            </a>
          </p>
          {/*<p>
            Or, make a reservation via phone by calling{" "}
            <a href="tel:888-373-9855">(888) 373-9855</a> (processing fees
            apply).
            <br />
            Tell them that you're in the <strong>CSICon</strong> group, or
            Center for Inquiry 2022, or you can use the group code, SFCFI2.
            </p>*/}
        </div>
      </div>
    </div>
  </Layout>
)

export default HotelPage
