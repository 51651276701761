import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionSpeakers from "../components/section-speakers"
import SectionSchedule from "../components/section-schedule"
import SectionUpdates from "../components/section-updates"
import SectionInfo from "../components/section-info"
import SplashVideo from "../assets/CSICon 2022 Promo Vid-3.mp4"

import "bootstrap/dist/css/bootstrap.min.css"
import { Carousel } from "react-bootstrap"

const IndexPage = () => (
  <div className="page-template-home-page">
    <Layout>
      <Seo title="CSICon 2023 Homepage" />
      <div className="csicon-slider ">
        <Carousel>
          <Carousel.Item>
            <a href="https://skepticalinquirer.org/csicon-2023-registration/?utm_source=csiconsite&utm_medium=button&utm_id=csicon">
              <StaticImage
                src="../images/Web_Slider_Nyev2.png"
                className="d-block w-100"
                objectFit="cover"
                loading="eager"
                placeholder="blurred"
                alt="csicon register"
                transformOptions="cropFocus: attention"
                height={900}
              />
            </a>
          </Carousel.Item>
          <Carousel.Item>
            <a href="https://watch.eventive.org/virulentmovie/play/65147bdf5434ce006e7f9c70">
              <StaticImage
                src="https://cdn.centerforinquiry.org/wp-content/uploads/sites/29/2023/10/06160231/virulent.png"
                className="d-block w-100"
                objectFit="cover"
                loading="eager"
                placeholder="blurred"
                alt="csicon register"
              />
            </a>
          </Carousel.Item>
          <Carousel.Item>
            <Link to="/speakers">
              <StaticImage
                src="../images/Web_Slider_2.png"
                className="d-block w-100"
                objectFit="cover"
                loading="eager"
                placeholder="blurred"
                alt="csicon speaker lineup"
                transformOptions="cropFocus: attention"
              />
            </Link>
          </Carousel.Item>
          <Carousel.Item>
            <Link to="/hotel">
              <StaticImage
                src="../images/Web_Slider_3.png"
                className="d-block w-100"
                objectFit="cover"
                loading="eager"
                placeholder="blurred"
                alt="csicon hotel information"
                transformOptions="cropFocus: attention"
              />
            </Link>
          </Carousel.Item>
          <Carousel.Item>
            <a href="https://www.youtube.com/playlist?list=PLFo5kdUdZWj4sPRy8DeXcVI9oQrTV2wS6">
              <StaticImage
                src="../images/Web_Slider_4.png"
                className="d-block w-100"
                objectFit="cover"
                loading="eager"
                placeholder="blurred"
                alt="Flamingo Hotel Las Vegas"
                transformOptions="cropFocus: attention"
              />
            </a>
          </Carousel.Item>
          <Carousel.Item>
            <a href="https://skepticalinquirer.org/csicon-2023-registration/?utm_source=csiconsite&utm_medium=button&utm_id=csicon">
              <StaticImage
                src="../images/Web_Slider_5.png"
                className="d-block w-100"
                objectFit="cover"
                loading="eager"
                placeholder="blurred"
                alt="Flamingo Hotel Las Vegas"
                transformOptions="cropFocus: attention"
              />
            </a>
          </Carousel.Item>
        </Carousel>
      </div>

      <SectionSpeakers />
      <div class="promo-row">
        <StaticImage
          src="../images/tinhat.png"
          objectFit="cover"
          loading="lazy"
          placeholder="blurred"
          className="flex-left-img"
          alt="Flamingo Hotel Las Vegas"
        />
        <div class="flex-right">
          <h3>Join us for CSICon 2023</h3>
          <p>Had enough of the tin foil hat stuff?</p>
          <p>
            We wake up every day, and almost immediately we’re confronted by
            misinformation, pseudoscience, and conspiracy theories. Whether it’s
            scary stories about tracking chips in vaccines, or cancer-causing
            windmills, or social media “influencers” pushing completely
            worthless, “all-natural” homeopathic junk…it’s everywhere. And it’s
            exhausting.
          </p>
          <p>But you have the power to help make it stop.</p>
          <p>
            You have the ability to stand up for reason, and science, and
            critical thinking.
          </p>
          <p>
            The Committee for Skeptical Inquiry has been doing it for nearly 50
            years. And we know lots of people just like us—just like{" "}
            <em>you</em>—who are tired of the nonsense and looking for the best
            ways to push back.
          </p>
          <p>
            The good news is, you don’t have to do it alone. Join CSI,{" "}
            <em>Skeptical Inquirer</em> magazine, and an amazing collection of
            scientists, skeptics, and investigators from all over the globe for{" "}
            <strong>CSICon 2023</strong>, October 26th through 29th at the
            Flamingo Las Vegas Hotel.
          </p>
          <p>
            Stop drowning in misinformation and nonsense. Get the tools you need
            to float above it – and help the rest of the world ditch the tin
            foil hat stuff, too.
          </p>
          {/*<div id="promo-buttons">
            <a
              className="btn white-button"
              href="https://skepticalinquirer.org/csicon-2022-registration/"
            >
              Register
            </a>
            </div>*/}
        </div>
      </div>
      <SectionSchedule />

      <SectionInfo />
    </Layout>
  </div>
)

export default IndexPage
