import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import downloadFile from "../images/Floorplan - FLV.pdf"
import { Link } from "gatsby"
import csiconmap from "../images/CSICon-Map.jpg" // Tell webpack this JS file uses this image

const MapPage = () => (
  <Layout>
    <Seo title="CSICon 2023 Conference Map" />
    <div className="row page-row">
      <div className="col-12 col-lg-8">
        <div className="page-header">
          <h1>
            <strong>Conference Map</strong>
          </h1>
          <hr />
        </div>

        <div className="entry-content conference-max mt-3">
          <StaticImage
            src="../images/CSICon-Map.jpg"
            alt="CSICon Flamingo Map"
            height="100%"
            loading="lazy"
            placeholder="tracedSVG"
          />
          <p>
            We have made a map available for the conference floor. Please use
            this to easily get around.
          </p>
          <p>
            {" "}
            <a href={downloadFile} download>
              Download the map
            </a>
            {` `}
          </p>
          <hr />
          <h3>Conference Notes</h3>
          <p>
            During CSICon 2023, all the opening remarks will be held in the
            Vista Ballroom and presented by{" "}
            <Link to="/speakers#hrab-george/"> George Hrab</Link>.
            <p>
              You can find all our book signings throughout the conference in
              Laughlin 1
            </p>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default MapPage
