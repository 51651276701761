import * as React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Seo from "../components/seo"

const RegistrationPage = () => (
  <Layout>
    <>
      <div class="row page-row">
        <div class="col-12 col-lg-8">
          <div class="page-header">
            <hr />
            <h1>
              <strong>Register</strong>
            </h1>
          </div>

          <div class="entry-content conference-max mt-3">
            <link
              rel="preload"
              href="https://static.everyaction.com/ea-actiontag/at.js"
              as="script"
              crossorigin="anonymous"
            ></link>
            <link
              rel="preload"
              href="https://static.everyaction.com/ea-actiontag/at.min.css"
              as="style"
            ></link>
            <script
              type="text/javascript"
              src="https://static.everyaction.com/ea-actiontag/at.js"
              crossorigin="anonymous"
            ></script>
            <link
              rel="preload"
              href="https://nvlupin.blob.core.windows.net/designs/CustomStylesheet_d4ad784fe5a8ff2841e4bc0f899195757980e1d517ba5bb365c5b4b25599332b.css"
              as="style"
            ></link>
            <div
              class="ngp-form"
              data-form-url="https://secure.everyaction.com/v1/Forms/Pbo21swaSUmbW5BESMicGQ2"
              data-custom-css="https://nvlupin.blob.core.windows.net/designs/CustomStylesheet_d4ad784fe5a8ff2841e4bc0f899195757980e1d517ba5bb365c5b4b25599332b.css"
              data-fastaction-endpoint="https://fastaction.ngpvan.com"
              data-inline-errors="true"
              data-fastaction-nologin="true"
              data-databag-endpoint="https://profile.ngpvan.com"
              data-databag="everybody"
              data-mobile-autofocus="false"
            ></div>
          </div>
        </div>
      </div>
    </>
  </Layout>
)

export default RegistrationPage
