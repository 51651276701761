import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PapersPage = () => (
  <Layout>
    <div class="row page-row">
      <div class="col-12 col-lg-8">
        <div class="page-header">
          <hr />
          <h1>
            <strong>Call for Papers</strong>
          </h1>
        </div>

        <div class="entry-content conference-max mt-3">
          <p>
            The Committee for Skeptical Inquiry is delighted to announce our
            annual Call for Papers.
          </p>
          <p>
            Successful applicants will be given an opportunity to present at the
            Sunday Morning Papers session of CSICon Las Vegas 2023.
          </p>
          <p>
            The tradition of the Sunday Morning Papers session recognizes new
            voices and new ideas and showcases them in the spotlight of the
            CSICon stage. If you have had success in communicating scientific
            skepticism, teaching critical thinking, combating pseudoscience, or
            furthering the mission of CSI in some new and significant way, we
            want to hear from you!
          </p>
          <p>
            Anyone may submit a request to present a paper. If your proposal is
            accepted you will be allotted 10 to 15 minutes for your
            presentation. Invitations to present will be giving to approximately
            six proposals. If your proposal is selected for further
            consideration, a written article and draft of your presentation
            slides will be required for final consideration. Please see the
            process of selection schedule below for further details.
          </p>
          <hr />
          <h3>Submission Instructions</h3>
          <p>
            Please email:&nbsp;
            <a
              href="mailto:rhall@csufresno.edu?subject=CSICon Paper Submission&amp;body=First Name: %0D%0ALast Name: %0D%0APosition/Affiliation: %0D%0AAddress: %0D%0A%0D%0AEmail: %0D%0APhone Number: %0D%0A%0D%0APresentation Title: %0D%0A%0D%0APlease attach your proposal in PDF or Word format to this email."
              target="_blank"
            >
              rhall@csufresno.edu
            </a>
            .
          </p>
          <p>
            In your message, include the following:&nbsp;
            <strong>
              <ul>
                <li>First Name</li>
                <li>Last Name</li>
                <li>Name of additional speaker (if any)</li>
                <li>Position/Affiliation</li>
                <li>Address</li>
                <li>Email</li>
                <li>Phone</li>
                <li>Presentation Title</li>
                <li>
                  Abstract: (Provide a concise summary of your proposed
                  presentation in at least 100 words. Outlines may be included.
                  A description of how the topic is relevant to the CFI mission
                  is mandatory.)
                </li>
                <li>
                  Publications/ References: (Please list any relevant references
                  and/or any of your own publications related to this
                  presentation, Include a link to your web page if appropriate.)
                </li>
              </ul>
            </strong>{" "}
            and attach your proposal in PDF or Word format.
          </p>
          <hr />
          <p>
            <strong>
              Relevant topics for papers include (but are not limited to):
            </strong>
          </p>
          <p>
            Analysis of questionable claims, rational examination of claimed
            paranormal phenomena, ideas in critical thinking education,
            questionable and alternative medicine, psychology of belief, or any
            of the usual topics of interest explored in Skeptical Inquiry
            magazine or that support the mission of the Committee for Skeptical
            Inquiry.
          </p>
          <p>
            <strong>Selection process schedule:</strong>
          </p>
          <ul>
            <li>
              Deadline for proposal submission: <strong>August 10, 2023</strong>
            </li>

            <li>
              Initial selection completed, request for further documentation:{" "}
              <strong>August 25, 2023</strong>
            </li>

            <li>
              Deadline for written article and draft presentation:{" "}
              <strong>September 10, 2023</strong>
            </li>

            <li>
              Final selection completed, invitations sent out:{" "}
              <strong>September 20, 2023</strong>
            </li>
          </ul>
          <p>
            If your proposal makes it through the initial selection, we will
            request from you a written paper and a draft of your presentation
            slides. The submitted paper should be at the level of a magazine
            article (like that of Skeptical Inquirer magazine) or a journal
            article appropriate for your professional discipline.
          </p>
          <p>
            <strong>Note:</strong> All invited Sunday Paper presenters are
            responsible to pay for their own attendance including registration.
            CSI greatly appreciates your volunteer participation, but
            unfortunately cannot fund any part of your travel, lodging, or
            conference registration.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default PapersPage
